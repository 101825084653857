import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/images/logo3.png'
import ceLogo from '../assets/images/ce-logo.png'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a target="_blank" href="https://twitter.com/wearewhitebear" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a target="_blank" href="https://www.facebook.com/White-Bear-Digital-102084487984410" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a target="_blank" href="https://linkedin.com/company/wearewhitebear" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
                <li style={{float: 'right'}}>
                    <img style={{
                        width: '150px',
                    }} alt="White Bear logo" src={logo}/>
                </li>
                <li style={{float: 'right'}}>
                    <img style={{
                        width: '100px',
                    }} alt="Cyber Essentials logo" src={ceLogo}/>
                </li>

            </ul>
            <ul className="copyright">
                <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
            <ul className="copyright">
                <li>&copy; White Bear Digital Ltd {new Date().getFullYear()}</li>
                <li>Registered in England & Wales</li>
                <li>Company Registration Number: 12373516</li>
                <li>VAT Number: 340236243</li>
            </ul>
        </div>
    </footer>
)

export default Footer
