import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'

const DEFAULT_KEYWORDS = [ 'whitebear', 'white bear' ]

class Layout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { 
            children,
            pageTitle = '',
            metaDescription = '',
            metaKeywords = []
        } = this.props

        const keywords = [...DEFAULT_KEYWORDS, ...metaKeywords].join(',');

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id='wrapper'>
                    <Header onToggleMenu={this.handleToggleMenu} />
                    <Helmet
                        title={'White Bear - ' + pageTitle}
                        meta={[
                            { name: 'description', content: metaDescription },
                            { name: 'keywords', content: keywords },
                        ]}
                    >
                    </Helmet>
                    {children}
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
            </div>
        )
    }
}

Layout.propTypes = {
    pageTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.array,
}

export default Layout
