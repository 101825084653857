import React from 'react'

export default () => {
    return (
        <form action="https://wearewhitebear.us4.list-manage.com/subscribe/post?u=6ea5464d70505b6331c2a0921&amp;id=c08a0d0145" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
            <h2>Subscribe to our newsletter</h2>
            
            <div className="field first">
                <label htmlFor="mce-FNAME">Name </label>
                <input type="text" name="FNAME" id="mce-FNAME" required="required"/>
            </div>

            <div className="field">
                <label htmlFor="mce-EMAIL">Email </label>
                <input type="email" name="EMAIL" id="mce-EMAIL" required="required"/>
            </div>

            <div className="field">
                <input type="checkbox" id="demo-human" name="demo-human" required="required"/>
                <label htmlFor="demo-human">I confirm I am 16 or over</label>
            </div>

            <div id="mce-responses">
                <div id="mce-error-response" style={{display:'none'}}></div>
                <div id="mce-success-response" style={{display:'none'}}></div>
            </div>    
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                <input type="text" name="b_6ea5464d70505b6331c2a0921_c08a0d0145" tabIndex="-1" />
            </div>

            <ul className="actions">
                <li><input type="submit" className="special" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"/></li>
                <li><input type="reset" value="Clear" /></li>
            </ul>
        </form>
    )
}