import React from 'react'
import Recaptcha from "react-google-recaptcha";
import MailChimp from './MailChimp';

export default class Contact extends React.Component {

    handleRecaptcha = value => {
        this.setState({ "g-recaptcha-response": value });
    };

    render () {
        let showThanks = false;
        if (typeof window !== 'undefined') {
            const { search } = (window || {}).location
            showThanks = search === '?posted=true'
        }
        return (
            <section id="contact">
                <div className="inner">
                    <section>
                        { showThanks 
                            ? <p className="thanks"><span role="img" aria-label="Wave emoji">👋</span> Thanks for getting in touch!</p> 
                            : null 
                        }
                        <form name="contact" method="post" action="?posted=true#footer" data-netlify-recaptcha="true" data-netlify="true" netlify-honeypot="sneaky">
                            <h2>Drop us a line</h2>
                            <div className="field hidden">
                                <label htmlFor="sneaky">Don’t fill this out if you're human</label>
                                <input type="text" name="sneaky"/>
                            </div>
                            <div className="field half first">
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" required="required" />
                            </div>
                            <div className="field half">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" required="required" />
                            </div>
                            <div className="field">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" rows="6" required="required"></textarea>
                            </div>
                            <input type="hidden" name="form-name" value="contact" required="required" />
                            <div className="field">
                                <Recaptcha sitekey="6LeQCcsUAAAAAGiUF92fxFIsjpcr2LQH3Yn6fN8I"/>
                            </div>
                            <ul className="actions">
                                <li><input type="submit" value="Send Message" className="special" /></li>
                                <li><input type="reset" value="Clear" /></li>
                            </ul>
                        </form>
                    </section>
                    <section className="split">
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-envelope"></span>
                                <h3>Email</h3>
                                <a href="#">hello@wearewhitebear.com</a>
                            </div>
                            <div className="contact-method">
                                <span className="icon alt fa-phone"></span>
                                <h3>Phone</h3>
                                <span>01902 239077</span>
                            </div>
                            <div className="contact-method">
                                <span className="icon alt fa-home"></span>
                                <h3>Address</h3>
                                <span>
                                    98 Grosvenor Road<br/>
                                    Ettingshall Park<br/>
                                    Wolverhampton<br/>
                                    England<br/>
                                    WV4 6QT
                                </span>
                            </div>
                        </section>
                        <section>
                            <MailChimp />
                        </section>
                    </section>
                </div>
            </section>
        )
    }
}